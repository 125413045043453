




import wAxios from '@/plugins/w/axios'

const api = {
	join: (hash) => wAxios.post_auth(`group-member/groups/join/${hash}`)
}

export default {
	components: {
		'z-loader': () => import('@/plugins/app@components/loader/z-loader.vue')
	},

	async mounted() {
		try {
			await api.join(this.$route.params?.hash)
			this.$router.push({ name: 'Groups' })
		} catch (err) {
			await this.$wToast.error(err)
			this.$router.push({ name: 'Profile' })
		}
	}
}
